@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-down(sm) {
  .skills {
    max-width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    justify-content: center;
  }

  .skill-wrapper {
    margin-bottom: 1em;
  }
}

@include media-breakpoint-up(md) {
  .skills {
    justify-content: center;
  }

  .skill-wrapper {
    margin-bottom: 2em;
  }
}


.skills {
  display: flex;
  flex-wrap: wrap;
}

.skill-wrapper {
  justify-content: center;
}