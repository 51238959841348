@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-down(sm) {
  .skill {
    padding: 3px 10px;
    margin-right: 0.2em;
  }
}

@include media-breakpoint-up(md) {
  .skill {
    padding: 6px 16px;
    margin-right: 0.5em;
  }
}

.skill {
  font-size: 16px;
  background-color: white;
  border-radius: 31px;
  margin-bottom: 2px;
  white-space: nowrap;
}
