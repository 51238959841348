@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-down(sm) {

  .header-left {
    margin-left: 5%;

    .back-home {
      font-size: 0.6em;
      z-index: 10;
      position: relative;
      display: flex;
      align-items: center;

      .back-home-arrow {
        margin: 0 1em;
      }
    }
  }

  .initials-square {
    width: 3em;
    height: 3em;
    background-color: black;
  }

  .initials {
    font-size: 22px;
    line-height: 48px;
  }

  .download-cv {
    width: 11em;
    padding: 0 0.5em;
    height: 2.5em;
    line-height: 2.5em;
    font-size: 0.8em;
    margin-right: 5%;
  }

}

@include media-breakpoint-up(md) {

  .header-left {
    margin-left: 10%;
    gap: 1em;

    .back-home {
      font-size: 0.9em;

      .back-home-arrow {
        margin-right: 0.57em;
      }
    }
  }

  .initials-square {
    width: 4.5em;
    height: 5em;
    background-color: black;
    line-height: 80px;
  }

  .initials {
    font-size: 42px;
  }

  .download-cv {
    width: 11em;
    padding: 0 0.5em;
    height: 2.5em;
    font-size: 1.25em;
    margin-right: 10%;
    line-height: 2.5em;
  }

}

.Header {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #36373a4D;
  backdrop-filter: blur(16px);
  z-index: 10;
}

.initials-square {
  display: block;
}

.initials {
  display: block;
  font-family: Roboto;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

a {
  color: white;
  text-decoration: none;

  .back-home {
    .back-home-arrow {
      margin-right: 0.57em;
    }
  }
}

.download-cv {
  z-index: 10;
  text-decoration: none;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: black;
  border-radius: 30px;
  top: 34%;
  bottom: 0;
  color: white;
  font-family: Roboto;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}