@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-down(sm) {
  .projects {
    top: 2em;
  }
  .project-image {
    width: 17em;
  }
  .project-button {
    padding: 5px 10px;
  }
  .project-footer {
    padding: 7px 13px;
  }
}

@include media-breakpoint-up(md) {
  .projects {
    top: 10em;
  }
  .project-image {
    height: 96%;
    width: 95%
  }

  .project-wrapper {
    width: 42%;

    .image-wrapper {
    }
  }
  .project-button {
    padding: 8px 15px;
  }
  .project-footer {
    padding: 10px 3% 10px 0;;
  }
}


.project-wrapper {
  margin-bottom: 3%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image-wrapper {
    height: 100%;

    .project-image {
      border-radius: 13px;
      border: solid 8px #363538;
      object-fit: cover;
      object-position: left top;
    }
  }

  .project-footer {
    display: flex;
    justify-content: space-between;

    .project-name {
      color: white;
      font-size: 1.5rem;
    }

    .project-button {
      background-color: transparent;
      color: white;
      border-radius: 13px;
      font-size: 13px;
      border: solid 1px white;
      cursor: pointer;
      outline: none;

      .button-arrow {
        margin-left: 6px;
      }
    }
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #292b2e;
}