@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-down(sm) {
  body {
    background: linear-gradient(180deg, #36373a 41%, #36373a 42%, #292b2e 42%, #292b2e 100%);
  }

  .hp-background-wave {
    margin-top: 75vh;
  }

  .curved-arrow {
    position: absolute;
    margin-left: calc(83% - 56px);
    top: 3%;
    width: 10%;
  }

  .profile-picture {
    width: 6em;
    height: auto;
    top: 11%;
  }

  .profile-picture-wrapper {
    margin-top: 5em;
    width: 6em;
    height: 6em;
  }

  .homepage-middle-text {
    margin-top: 1em;
  }

  .homepage-title {
    font-size: 65px;
    margin-top: 0;
  }

  .homepage-summary {
    font-size: 16px;
    margin-top: 0;
    max-width: 98%;

    .text-break {
      display: none;
    }
  }

  .projects-link {
    font-size: 16px;
  }

  .next-page {
    padding: 5em 0;
    .down-arrow {
      height: 0.8em;
    }
  }

  .homepage-footer {
    padding-top: 5em;

    .footer-top-text {
      margin-left: 5%;
    }

    .footer-bottom-text {
      text-align: right;
      margin-right: 5%;
    }

  }

  .footer-text {
    font-size: 3em;
  }

  .footer-contact {
    margin: 3em auto;

    .phone {
      margin-right: 65px;
    }

    .phone-img, .mail-img {
      height: 55px;
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    background: linear-gradient(180deg, #36373a 21%, #36373a 39%, #292b2e 59%, #292b2e 100%);
  }

  .hp-background-wave {
    margin-top: 85vh;
  }

  .curved-arrow {
    position: absolute;
    top: 6em;
    width: 15%;
    margin-left: calc(80% - 130px);
  }

  .profile-picture {
    width: 12em;
    height: auto;
    top: 18%;
    left: calc(50% - 95px);
  }

  .profile-picture-wrapper {
    margin-top: 7em;
    margin-bottom: 4em;
    width: 12em;
    height: 12em;
  }

  .homepage-middle-text {

    .homepage-title {
      font-size: 120px;
    }

    .homepage-summary {
      font-size: 30px;
      margin-top: 0;
    }
  }

  .next-page {
    padding: 7em 0 5em;
    .projects-link {
      font-size: 20px;
    }
    .down-arrow {
      height: 0.8em;

    }
  }

  .homepage-footer {
    padding-top: 8em;
    text-align: center;

    .footer-text {
      font-size: 7.5em;
    }

    .footer-bottom-text {
      margin-left: 25%;
    }

    .footer-contact {
      margin: 5em auto;

      .phone {
        margin-right: 100px;
      }

    }
  }

}

@media (min-width: 1600px) {
  .next-page {
    padding: 12em 0 7em;
  }
  .profile-picture-wrapper {
    margin: 8em 0 0;
  }
}

.hide-back-home {
  display: none;
}

.curved-arrow {
  fill: #ffffff
}

.profile-picture-wrapper {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.homepage-title {
  font-family: Roboto;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-bottom: 0;
}

.homepage-summary {
  font-family: Roboto;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.projects-link {
  color: white;
  margin-bottom: 10px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2.5em;
}

.next-page {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/assets/wave-hp.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.homepage-footer {
  display: flex;
  flex-direction: column;
  background-color: #292b2e;

  .footer-text {
    font-family: Roboto;
    color: white;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .footer-bottom-text {
    margin-left: 25%;
  }

}

.hp-background {
  position: absolute;
  top: 0;
  z-index: -1;

  .hp-background-wave {
    width: 100%;
    object-fit: contain;
    opacity: 0.97;
  }

  .hp-background-image {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
  }

  .hp-background-endless {
    margin-top: -10px;
    position: relative;
    background: #292b2e;
    height: 65em
  }

}

